body {
  background: linear-gradient(
      74.29deg,
      rgba(20, 23, 36, 0.2) 1.04%,
      rgba(35, 44, 69, 0.2) 97.27%
    ),
    linear-gradient(105.84deg, #293550 0.34%, #141827 99.13%);
  color: #fff;
}
.left {
  background-image: url(./assets/images/bg.png);
  position: relative;
  background-size: cover;
}
.right {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(7, 45, 66) 0%,
    rgb(0, 13, 28) 100%
  );
}
.left::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 12, 26, 0.6) 0%,
    rgb(0, 12, 26) 100%
  );
  height: 100%;
}
